import service from '@/utils/request'

//答复列表
export function feedback_replies(params){
    return service({
        url:'api/feedback/replies',
        method:'get',
        params
    })
}
//我要留言
export function feedback(data){
    return service({
        url:'api/feedback',
        method:'post',
        data
    })
}
//我的留言列表
export function feedback_list(params){
    return service({
        url:'api/feedback/list',
        method:'get',
        params
    })
}
//留言详情
export function feedback_detail(id){
    return service({
        url:'api/feedback/'+id,
        method:'get',
    })
}
//留言统计
export function feedback_statistic(){
    return service({
        url:'api/feedback/statistic',
        method:'get',
    })
}


//我的发票
export function invoice_list(params){
    return service({
        url:'api/invoice/list',
        method:'get',
        params
    })
}
