<template>
    <div class="statistics">
        <div class="content">
            <div class="line_01">数据统计</div>
            <div>
                <div v-for="(item,i) in list" :key="i" style="margin-bottom:20px;">
                    <div style="background:#EFD8D7;color:#CC0000;padding:13px 27px;">{{item.period}}留言办理情况</div>
                    <div class="fx" style="padding:22px 33px;border:1px solid #E7E7E7;">
                        <div style="flex:1;">
                            <table border="1" style="border-collapse:collapse;border-color:#E7E7E7;">
                                <tr>
                                    <td class="left">收件数</td>
                                    <td class="right">{{item.total}}件</td>
                                </tr>
                                <tr>
                                    <td class="left">已办结</td>
                                    <td class="right">{{item.verifyed}}件</td>
                                </tr>
                            </table>
                        </div>
                        <div :id="'main'+i" style="flex:1;height:200px;"></div>
                    </div>
                </div>
                <!-- 分页管理 -->
                <Page v-if="list.length!=0" :pageSize="page_size" :total="total" :current="page" @change="pageChange"/>
                <div class="t-center" style="padding:40px 0px;" v-else>
                    <img src="shexie/img/nodata1.png" alt="">
                    <div>暂无数据</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {feedback_statistic} from '@/api/feedback.js'
export default {
    data() {
        return {
            page_size:10,   //每页条数
            total:0,        //总条数
            page:1,        //当前页
            list:[]
        };
    },
    created() {
        this.getList()
    },
    mounted() {
        
    },
    methods: {
        getList(){
            feedback_statistic({page:this.page,page_size:this.page_size}).then(res=>{
                if(res.code==200){
                    this.list = res.data.data
                    this.total = res.data.total
                    this.$nextTick(()=>{
                        this.echartsInit()
                    })
                }else{
                    this.list = []
                    this.total = 0
                }
            })
        },
        //分页
        pageChange(val){
            this.page  = val;
            this.getList()
        },
        echartsInit() {
            let color = ['#ED563F','#FFB0B0','#9D1615']
            //柱形图
            for(var i in this.list){
                //因为初始化echarts 的时候，需要指定的容器 id='main'
                this.$echarts.init(document.getElementById('main'+i)).setOption({
                    title: {
                        text: '留言分布情况',
                        left: 'center'
                    },
                    legend: {
                        orient: 'vertical',
                        right: 10,
                        bottom: 0,
                    },
                    graphic: [{
                        type: 'text',
                        left: 'center',
                        top: '35%',
                        z: 10,
                        style: {
                            textAlign: 'center',
                            fill: '#1a1a1a',
                            text: '',
                            font: '16px Microsoft YaHei'
                        }
                    }],
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['25%', '45%'],
                            center: ['50%', '60%'],
                            avoidLabelOverlap: false,
                            hoverAnimation:false,
                            slinet:true,
                            color: color,
                            label: {
                                formatter:'{b}: {c} 件'
                            },
                            labelLine: {
                                normal: {
                                    show: true,
                                    length: 15,
                                    length2: 20,
                                    lineStyle: {
                                        color: '#808080'
                                    },
                                }
                            },
                            data: this.list[i].scatter,
                        }
                    ]
    
                })
            }
        }
    }
};
</script>

<style scoped lang="scss">
    .statistics{
        margin: 20px auto;
        .content{
            margin-top: 40px;
            .line_01{ 
                width:100px;
                padding: 0 20px 0;
                margin:0px auto 30px;  
                line-height: 2px;  
                color: #CD0000;
                font-weight: bold;
                border-left: 80px solid #CD0000;  
                border-right: 80px solid #CD0000;  
                text-align: center;  
            }
            .left{
                width: 90px;
                text-align: center;
                line-height: 68px;
            }
            .right{
                width: 168px;
                line-height: 68px;
                text-align: center;
            }
        }
    }
</style>
